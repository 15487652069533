import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { validate, clean, format } from 'rut.js';
import AlertDissmisible from '../../../common/AlertDismissible';
import Captcha from './../../../common/Captcha';
import VoluntarioService from './../../Voluntario/VoluntarioService';
import Gracias from './../Gracias';

class Registro extends React.Component {
  state = {
    inputs: {
      socialNumber: '',
      profession: [],
      fullName: '',
      desiredCategory: 1,
      email: '',
      weeklyHours: 1,
      cellphoneNumber: '',
      noPaidAgreement: true,
      personType: 2,
      country: 0,
      'g-recaptcha-response': '',
    },
    errors: {
      socialNumber: null,
      profession: false,
      fullName: null,
      desiredCategory: false,
      email: null,
      weeklyHours: false,
      cellphoneNumber: null,
      noPaidAgreement: null,
      personType: false,
      country: null,
      'g-recaptcha-response': null,
    },
    showAlert: false,
    alertVariant: '',
    isLoading: false,
    countries: [],
  };

  captchaRef = React.createRef();

  componentDidMount = () => {
    this.fetchCountries();
  };

  fetchCountries = () => {
    VoluntarioService.countries().then((response) => {
      this.setState({
        countries: response.data.sort((a, b) => {
          if (a.name > b.name) {
            return 1;
          }
          if (a.name < b.name) {
            return -1;
          }
          return 0;
        }),
      });
    });
  };

  onChange = (e) => {
    this.setState(
      {
        inputs: {
          ...this.state.inputs,
          [e.target.name]:
            e.target.name === 'socialNumber'
              ? format(clean(e.target.value))
              : e.target.value,
        },
        errors: {
          ...this.state.errors,
          [e.target.name]:
            e.target.name === 'socialNumber'
              ? !validate(clean(e.target.value))
              : false,
        },
      },
      () => {
        this.formCheck();
      }
    );
  };

  handleOnBlur = (event) => {
    const { inputs } = this.state;
    if (inputs[event.target.name].length === 0) {
      this.setState(
        {
          errors: {
            ...this.state.errors,
            [event.target.name]: true,
          },
        },
        () => {
          this.formCheck();
        }
      );
    }
  };

  formCheck = () => {
    const { errors } = this.state;
    let hasErrors = Object.entries(errors)
      .filter(([_key, value]) => value === null || value === true)
      .reduce((obj, [key, value]) => {
        obj[key] = value;
        return obj;
      }, {});

    return hasErrors;
  };

  onSubmit = (event) => {
    this.setState({
      isLoading: true,
    });
    event.preventDefault();
    const { inputs } = this.state;
    VoluntarioService.register(inputs).then(
      (_response) => {
        this.setState({
          showAlert: true,
          alertVariant: 'success',
          isLoading: false,
        });
      },
      (_error) => {
        console.log(_error);
        this.captchaRef.current.props.grecaptcha.reset();
        this.setState({
          showAlert: true,
          alertVariant: 'info',
          isLoading: false,
        });
      }
    );
  };

  onAlertClose = () => {
    this.setState({
      showAlert: false,
      alertVariant: '',
    });
  };

  recaptchaOnChange = (state) => {
    this.setState(state, () => {
      this.formCheck();
    });
  };

  recaptchaExpired = (state) => {
    state.isLoading = false;
    this.setState(...state);
  };

  render() {
    const {
      inputs,
      errors,
      showAlert,
      alertVariant,
      isLoading,
      countries,
    } = this.state;

    const { type } = this.props;

    let alert = (
      <AlertDissmisible
        show={showAlert}
        variant={alertVariant}
        onAlertClose={this.onAlertClose}
      />
    );

    if (!showAlert || alertVariant === '') {
      alert = '';
    }

    return alertVariant === 'success' ? (
      <Gracias />
    ) : (
      <Container>
        {alert}
        <Row>
          <Col md={{ span: 10, offset: 1 }}>
            <div className="box">
              <div className="box-header p-3">
                <h2>Registrate, es muy fácil!</h2>
                <p>
                  Primero debes crear tu cuenta para poder crear tus reuniones
                  que requieras en las diferentes categorías que tenemos para
                  ti.
                </p>
              </div>
              <div className="box-content p-3">
                <Form onSubmit={this.onSubmit}>
                  <Row>
                    <Col xs={12} md={6} lg={6}>
                      <Form.Group>
                        <Form.Label>Tu rut</Form.Label>
                        <Form.Control
                          name="socialNumber"
                          onChange={this.onChange}
                          onBlur={this.handleOnBlur}
                          required
                          type="text"
                          placeholder="Sin puntos ni guiones"
                          value={inputs.socialNumber}
                          className={!errors.socialNumber ? 'valid' : 'invalid'}
                        />
                        {!errors.socialNumber ? (
                          <Form.Control.Feedback>
                            Rut Válido
                          </Form.Control.Feedback>
                        ) : (
                          <></>
                        )}
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={6} lg={6}>
                      <Form.Group>
                        <Form.Label>¿Cuál es tu nombre completo?</Form.Label>
                        <Form.Control
                          name="fullName"
                          onChange={this.onChange}
                          onBlur={this.handleOnBlur}
                          required
                          type="text"
                          placeholder="Por favor comparte tu nombre y apellidos"
                          className={!errors.fullName ? 'valid' : 'invalid'}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={6} lg={6}>
                      <Form.Group>
                        <Form.Label>¿Cuál es tu email?</Form.Label>
                        <Form.Control
                          name="email"
                          onChange={this.onChange}
                          onBlur={this.handleOnBlur}
                          required
                          type="email"
                          placeholder="mi@correo.com"
                          className={!errors.email ? 'valid' : 'invalid'}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={6} lg={6}>
                      <Form.Group>
                        <Form.Label>Tu número de celular</Form.Label>
                        <Form.Control
                          name="cellphoneNumber"
                          onChange={this.onChange}
                          onBlur={this.handleOnBlur}
                          required
                          type="text"
                          maxLength={9}
                          placeholder="9 1111 2222"
                          className={
                            !errors.cellphoneNumber ? 'valid' : 'invalid'
                          }
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={12} lg={12}>
                      <Form.Group>
                        <Form.Label>
                          Me comprometo a generar reuniones útiles para todos
                          los usuarios que utilizan Converge
                        </Form.Label>
                        <div className="radios">
                          <Form.Check
                            name="noPaidAgreement"
                            inline
                            label="Si"
                            type="radio"
                            value={true}
                            onChange={this.onChange}
                          />
                          <Form.Check
                            name="noPaidAgreement"
                            inline
                            label="No"
                            type="radio"
                            value={false}
                            onChange={this.onChange}
                          />
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={6} lg={6}>
                      <Form.Group>
                        <Form.Label>
                          ¿Selecciona en que país te encuentras?
                        </Form.Label>
                        <Form.Control
                          name="country"
                          onChange={this.onChange}
                          onBlur={this.handleOnBlur}
                          required
                          as="select"
                          className={!errors.country ? 'valid' : 'invalid'}
                        >
                          <option value="">Selecciona una opción</option>
                          {countries.map((country) => {
                            return (
                              <option
                                key={`option-${country.id}`}
                                value={country.id}
                              >
                                {country.name}
                              </option>
                            );
                          })}
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={6} lg={6}>
                      <Captcha
                        captchaRef={this.captchaRef}
                        recaptchaOnChange={this.recaptchaOnChange}
                        recaptchaExpired={this.recaptchaExpired}
                        inputs={inputs}
                        errors={errors}
                      />
                    </Col>
                  </Row>
                  <div className="actions">
                    <Link
                      to="/"
                      className="btn btn-secondary btn-outline btn-lg"
                    >
                      No estoy seguro
                    </Link>
                    <Button
                      variant="primary"
                      size="lg"
                      type="submit"
                      disabled={isLoading}
                    >
                      {isLoading ? 'Cargando...' : 'Aplicar'}
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Registro;
