import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

function Gracias(props) {
  const { name, email } = props;
  return (
    <Container>
      <Row>
        <Col md={{ span: 8, offset: 2 }}>
          <div className="box">
            <div className="box-header p-3">
              <h2>Todo listo {name}</h2>
              <p>Gracias por registrarte con nosotros!</p>
              <div className="box-content">
                <p>
                  Te hemos enviado un correo a tu email <strong>{email}</strong>{' '}
                  tu usuario y contraseña para que puedas ingresar a la
                  plataforma y comiences a crear tus solicitudes con nuestro
                  equipo de Converge!
                </p>
                <p>
                  Cualquier duda por favor no dudes en escribirnos a
                  contacto@Converge.com.
                </p>
                <p>Equipo Converge.</p>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Gracias;
