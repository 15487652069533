import React, { useEffect, useState, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { Container, Row, Col, Media, Image } from 'react-bootstrap';
import JitsiMeet from './../../../../common/JitsiMeet';
import PortalService from './../../PortalService';
import { ProfileContext } from './../../../../app/Context/ProfileContext';
import socketIOClient from 'socket.io-client';
import Skeleton from 'react-loading-skeleton';

const VideoCall = (props) => {
  const socket = socketIOClient(process.env.REACT_APP_SOCKET);
  const [currentCase, setCurrentCase] = useState(null);
  const [showSurvey, setShowSurvey] = useState(false);
  const [loading, setLoading] = useState(false);
  const { profile } = useContext(ProfileContext);
  const [showJitsi, setShowJitsi] = useState(false);

  useEffect(() => {
    async function fetchData() {
      const { location, history } = props;
      if (!location.state) {
        return history.push('/meetings');
      }

      const response = await PortalService.singleCase(location.state.myCase);
      const data = await response.data;
      setCurrentCase(data);
      setLoading(true);

      if (profile.id === data.owner.id) {
        setShowJitsi(true);
      }
    }
    if (!loading) {
      fetchData();
      if (currentCase) {
        socket.emit('join', { profile, room: currentCase.id });
        socket.off('userJoined').on('userJoined', (data) => {
          if (
            profile.id !== currentCase.owner.id &&
            data.profile.id === currentCase.owner.id
          ) {
            setShowJitsi(true);
          } else {
            socket.emit('join', { profile, room: currentCase.id });
          }
        });
      }
    }

    return () => {
      if (currentCase && showJitsi) {
        socket.emit('leave', { room: currentCase.id });
      }
    };
  }, [currentCase]);

  return (
    <Container className="dashboard">
      {currentCase ? (
        <Row>
          <Col lg={{ span: 8 }} md={{ span: 8 }}>
            <div className="box p-3" style={{ boxShadow: 'none' }}>
              <div className="box-header mb-5">
                <h2>
                  Reunion con{' '}
                  {profile.id !== currentCase.owner.id
                    ? currentCase.owner.fullName
                    : currentCase.schedule.volunteer.fullName}
                </h2>
              </div>
              <div className="box-content">
                {showSurvey ? (
                  <iframe
                    src="https://docs.google.com/forms/d/e/1FAIpQLSdqthA0VcdncrpHQV8fRe9TzPzHb2LkHJuuB6z3kSL9uOE1Rw/viewform?embedded=true"
                    width="100%"
                    height="1000"
                    frameBorder="0"
                    marginHeight="0"
                    name="survey"
                    marginWidth="0"
                  >
                    Cargando…
                  </iframe>
                ) : showJitsi ? (
                  <JitsiMeet
                    myCase={currentCase}
                    profile={profile}
                    showSurvey={setShowSurvey}
                  />
                ) : !showJitsi && !showSurvey ? (
                  <>
                    <h4 className="text-center">
                      Estamos esperando al anfitrión para comenzar
                    </h4>
                    <Skeleton height={300} />
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </Col>
          <Col
            lg={{ span: 4 }}
            md={{ span: 4 }}
            className="animate__animated animate__slideInUp"
          >
            <div className="box p-3">
              <div className="box-header mb-5">
                <h2>Integrantes</h2>
              </div>
              <div className="box-content">
                <Media>
                  <Image
                    roundedCircle
                    width={64}
                    height={64}
                    className="align-self-start mr-3"
                    src={currentCase.owner.avatar}
                    alt="Generic placeholder"
                  />
                  <Media.Body>
                    <h5>{currentCase.owner.fullName}</h5>
                    <p>Solicitante</p>
                  </Media.Body>
                </Media>
                <br />
                <Media>
                  <Image
                    roundedCircle
                    width={64}
                    height={64}
                    className="align-self-start mr-3"
                    src={currentCase.schedule.volunteer.avatar}
                    alt="Generic placeholder"
                  />
                  <Media.Body>
                    <h5>{currentCase.schedule.volunteer.fullName}</h5>
                    <p>Voluntario</p>
                  </Media.Body>
                </Media>
              </div>
            </div>
          </Col>
        </Row>
      ) : (
        <></>
      )}
    </Container>
  );
};
export default withRouter(VideoCall);
