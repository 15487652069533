import React from 'react';
import { withRouter } from 'react-router-dom';
import { Container, Row, Col, Image, ProgressBar } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEnvelope,
  faPhoneAlt,
  faGlobeAmericas,
} from '@fortawesome/free-solid-svg-icons';
import PortalService from './../PortalService';
import ScheduleForm from './ScheduleForm';
import ConfirmationModal from './ConfirmationModal';

class Case extends React.Component {
  state = {
    singleCase: null,
    progress: 2,
    isLoaded: false,
    showProgress: true,
    showModal: false,
    schedule: { startTime: '', endTime: '' },
  };

  componentDidMount = () => {
    const { location, history } = this.props;
    if (!location.state) {
      return history.push('/dashboard');
    }
    const { caseId } = location.state;

    PortalService.singleCase(caseId).then((response) => {
      this.setState({
        singleCase: response.data,
        progress: 100,
        isLoaded: true,
      });
      setTimeout(() => {
        this.setState({
          progress: 2,
          showProgress: false,
        });
      }, 500);
    });
  };

  getSchedule = (schedule) => {
    this.setState({
      ...this.state,
      schedule,
    });
  };

  onSubmit = () => {
    this.setState({
      showModal: true,
    });
  };

  setSchedule = () => {
    const { caseId } = this.props.location.state;
    const { schedule } = this.state;
    const { history } = this.props;

    const payload = {
      case: caseId,
      startTime: schedule.startTime,
      endTime: schedule.endTime,
    };

    PortalService.schedule(payload).then(
      (_response) => {
        this.setState(
          {
            showModal: false,
          },
          () => {
            history.push('/dashboard', {
              success: true,
            });
          }
        );
      },
      (error) => {
        this.setState({
          showModal: false,
        });
      }
    );
  };

  render() {
    const {
      singleCase,
      progress,
      showProgress,
      showModal,
      schedule,
      isLoaded,
    } = this.state;
    return (
      <Container className="case">
        {showProgress ? <ProgressBar animated now={progress} /> : <></>}
        {isLoaded ? (
          <>
            <ConfirmationModal
              show={showModal}
              onHide={() => {
                this.setState({ showModal: false });
              }}
              singleCase={singleCase}
              schedule={schedule}
              onSchedule={this.setSchedule}
            />
            <Row>
              <Col xs={12} sm={12} lg={8}>
                <div className="box">
                  <div className="box-header">
                    <div>
                      <Image
                        width="70"
                        src={singleCase.owner.avatar}
                        roundedCircle
                      />
                    </div>
                    <div>
                      <h2>{singleCase.owner.fullName}</h2>
                    </div>
                  </div>
                  <div className="box-content pl-4 pb-5 pr-4">
                    <p>{singleCase.description}</p>
                    <h4>Datos de Contacto</h4>
                    <div className="contact-info pt-2">
                      <p>
                        <FontAwesomeIcon icon={faEnvelope} className="mr-3" />
                        {singleCase.owner.email}
                      </p>
                      <p>
                        <FontAwesomeIcon icon={faPhoneAlt} className="mr-3" />
                        {singleCase.owner.cellphoneNumber}
                      </p>
                      <p>
                        <FontAwesomeIcon
                          icon={faGlobeAmericas}
                          className="mr-3"
                        />
                        {singleCase.owner.country.name}
                      </p>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={12} lg={4}>
                <div className="box">
                  <div className="box-content p-3">
                    <ScheduleForm
                      getSchedule={this.getSchedule}
                      onSubmit={this.onSubmit}
                      singleCase={singleCase}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </>
        ) : (
          <></>
        )}
      </Container>
    );
  }
}

export default withRouter(Case);
