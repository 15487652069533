import React from 'react';
import { withRouter } from 'react-router-dom';
import { Container, Col, Row } from 'react-bootstrap';
import WordpressService from './../WordpressService';
import Skeleton from 'react-loading-skeleton';

class Post extends React.Component {
  state = {
    post: null,
  };
  async componentDidMount() {
    this.fetchPost();
  }

  async fetchPost() {
    const {
      match: { params },
    } = this.props;

    let response = await WordpressService.getPost(params.id);
    let post = await response.data;
    this.setState({
      post,
    });
  }

  render() {
    const { post } = this.state;
    return (
      <Container className="dashboard">
        <Row>
          <Col lg={{ span: 8, offset: 2 }} md={{ span: 8, offset: 2 }} sm={12}>
            <div className="box" style={{ boxShadow: 'none' }}>
              <div className="box-header mb-5">
                {!post ? <Skeleton /> : <h2>{post.title.rendered}</h2>}
              </div>
              <div className="box-content">
                {!post ? (
                  <Skeleton count="10" />
                ) : (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: post.content.rendered,
                    }}
                  ></div>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withRouter(Post);
