import request from './../../utilities/request-wordpress';

const categories = () => {
  return request({
    method: 'GET',
    url: '/categories',
  });
};

const posts = (categories) => {
  return request({
    method: 'GET',
    url: '/posts',
    params: {
      categories: categories,
    },
  });
};

const getPost = (id) => {
  return request({
    method: 'GET',
    url: `/posts/${id}`,
  });
};

const getPage = (id) => {
  return request({
    method: 'GET',
    url: `/pages/${id}`,
  });
};

const WordpressService = {
  categories,
  posts,
  getPost,
  getPage,
};

export default WordpressService;
