import React from 'react';
import { Jumbotron, Container, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import HeaderCarousel from './../../common/HeaderCarousel';

class Home extends React.Component {
  render() {
    return (
      <>
        <HeaderCarousel />

        <Jumbotron className="text-center mt-100">
          <Container>
            <h1>Registrate ya!</h1>
            <p>
              Crea una cuenta y comienza a usar Converge para estar en contacto
              y dar marcha a tus reuniones.
            </p>
            <p>
              <Link to="/signup" className="btn btn-primary btn-lg">
                Registrarme
              </Link>
            </p>
          </Container>
        </Jumbotron>
      </>
    );
  }
}

export default Home;
