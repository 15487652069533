import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

function Gracias(props) {
  const { name, email } = props;
  return (
    <Container>
      <Row>
        <Col md={{ span: 8, offset: 2 }}>
          <div className="box">
            <div className="box-header p-3">
              <h2>Gracias por ayudarnos {name}</h2>
              <p>Gracias por registrarte con nosotros!</p>
              <div className="box-content">
                <p>
                  Pronto enviaremos un correo a tu email{' '}
                  <strong>{email}</strong> con tu usuario y contraseña para que
                  puedas ingresar a la plataforma y comiences a agendar tus
                  reuniones con gente que te necesita!
                </p>
                <p>
                  Cualquier duda por favor no dudes en escribirnos a
                  contacto@Converge.com.
                </p>
                <p>
                  <strong>Equipo Converge.</strong>
                </p>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Gracias;
