import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import logo from './../../assets/img/logo.svg';

function Footer() {
  return (
    <footer className="footer">
      <div className="layer">
        <Container className="pt-5">
          <Row>
            <Col>
              <Image src={logo} height={80} alt="Fundación Emprender" />
            </Col>
          </Row>
          <Row>
            <Col sm={12} lg={6}>
              <p style={{ paddingLeft: 80 }}>Powered by Converge</p>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="info text-center">
        <p>Copyright Converge 2020</p>
      </div>
    </footer>
  );
}

export default Footer;
