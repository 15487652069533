import React, { useState } from 'react';
import { Alert } from 'react-bootstrap';

function AlertDismissible(props) {
  const [show, setShow] = useState(props.show);

  function onClose() {
    setShow(false);
    props.onAlertClose();
  }

  return show ? (
    <Alert variant={props.variant} onClose={onClose} dismissible>
      <Alert.Heading>
        {props.variant === 'success'
          ? 'Accion exitosa!'
          : 'Tuvimos un problema!'}
      </Alert.Heading>
      <p>
        {props.variant === 'success'
          ? 'Cambios realizados exitosamente'
          : `Al parecer hay un error en algunos de los campos, revisalos de nuevo y
        vuelve a intentar`}
      </p>
    </Alert>
  ) : (
    <></>
  );
}

export default AlertDismissible;
