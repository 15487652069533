import React from 'react';
import { Switch } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import Routes from '../app/Routes/routes';

function Router(props) {
  return (
    <Switch>
      {Routes.classic.map((route, key) => {
        return route.isPublic ? (
          <PublicRoute
            key={key}
            restricted={route.restricted}
            exact={route.exact}
            path={route.path}
            component={route.component}
          />
        ) : (
          <PrivateRoute
            key={key}
            restricted={route.restricted}
            exact={route.exact}
            path={route.path}
            component={route.component}
          />
        );
      })}
    </Switch>
  );
}

export default Router;
