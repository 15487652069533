import request from './../../utilities/request';

function login(payload) {
  return request({
    url: '/login',
    method: 'POST',
    data: payload,
  });
}

const LoginService = {
  login,
};

export default LoginService;
