const TOKEN_KEY = 'token';
const ROLE_KEY = 'role';

export const isLogin = () => {
  if (localStorage.getItem(TOKEN_KEY)) {
    return true;
  }

  return false;
};

export const logout = () => {
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem(ROLE_KEY);
};

export const user = () => {
  const token = localStorage.getItem(TOKEN_KEY);
  let userObject = {};
  if (token) {
    userObject = JSON.parse(atob(token.split('.')[1])).user;
  }
  return userObject;
};

export const refreshToken = (token) => {
  return localStorage.setItem(TOKEN_KEY, token);
};

export const setRole = (role) => {
  return localStorage.setItem(ROLE_KEY, role);
};

export const getRole = () => {
  return localStorage.getItem(ROLE_KEY);
};
