import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import WordpressService from './../../Content/WordpressService';
import Skeleton from 'react-loading-skeleton';

const PublicPage = (props) => {
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(null);

  useEffect(() => {
    if (loading) {
      WordpressService.getPage(props.id).then((response) => {
        setLoading(false);
        setPage(response.data);
      });
    }
  }, [props.page]);

  return (
    <Container className="dashboard">
      <Row>
        <Col lg={{ span: 10, offset: 1 }} md={{ span: 10, offset: 1 }} sm={12}>
          <div className="box" style={{ boxShadow: 'none' }}>
            <div className="box-header mb-5">
              {!page ? <Skeleton /> : <h2>{page.title.rendered}</h2>}
            </div>
            <div className="box-content">
              {!page ? (
                <Skeleton count="10" />
              ) : (
                <div
                  dangerouslySetInnerHTML={{
                    __html: page.content.rendered,
                  }}
                ></div>
              )}
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default PublicPage;
