import React from 'react';
import { withRouter } from 'react-router-dom';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import PortalService from './../PortalService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle, faCompass } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment-timezone';
import EmptyDataSet from './../../../common/EmptyDataSet';
import AlertDismissible from './../../../common/AlertDismissible';
import ConfirmationModal from './../Case/ConfirmationModal';
import { ProfileContext } from './../../../app/Context/ProfileContext';

class Cases extends React.Component {
  state = {
    meetings: [],
    emptyDataSet: false,
    showAlert: false,
    alertVariant: '',
    showModal: false,
    singleCase: null,
  };

  componentDidMount = () => {
    this.fetchCases();
  };

  fetchCases = () => {
    PortalService.myCases().then((response) => {
      const meetings = response.data.sort((a, b) => {
        if (a.schedule.endTime < b.schedule.endTime) {
          return 1;
        }
        if (a.schedule.endTime > b.schedule.endTime) {
          return -1;
        }
        return 0;
      });
      this.setState({
        ...this.state,
        meetings: meetings.map((row) => {
          return this.calculate(row);
        }),
      });

      setTimeout(() => {
        this.setState({
          emptyDataSet: true,
        });
      }, 100);
    });
  };

  reject = (caseId) => {
    const { meetings } = this.state;
    const payload = {
      case: caseId,
    };
    PortalService.rejectCall(payload).then((response) => {
      this.setState({
        meetings: meetings.map((myCase) => {
          return myCase.id === response.data.case.id
            ? this.calculate(response.data.case)
            : myCase;
        }),
        alertVariant: 'success',
        showAlert: true,
      });
    });
  };

  calculate = (row) => {
    let now = moment().tz('America/Santiago').unix();
    let startTime = moment(row.schedule.startTime, 'X')
      .tz('America/Santiago')
      .format('YYYY-MM-DD HH:mm');

    let labelButton = '';
    let activeButton = false;
    let action = () => {};

    if (row.schedule.scheduleStatus.id === 1) {
      labelButton = 'Abierta';
    } else if (row.schedule.scheduleStatus.id === 2) {
      if (now > row.schedule.endTime) {
        labelButton = 'Invitacion vencida';
      } else {
        activeButton = true;
        labelButton = 'Aceptar ayuda';
      }
    } else {
      if (now > row.schedule.endTime) {
        labelButton = 'Finalizada';
      } else {
        const nowFormat = moment(moment(now, 'X').format('YYYY-MM-DD HH:mm'));
        var hours = nowFormat.diff(startTime, 'hours');
        var minutes = hours / 24;
        var timeArray = Math.abs(minutes).toString().split('.');

        if (hours < 0) {
          labelButton = `Faltan ${timeArray[0]} dias y ${Math.abs(
            hours
          )} horas para acceder`;
          activeButton = false;
        } else {
          labelButton = 'Entrar';
          activeButton = true;
          action = this.goToCall;
        }
      }
    }
    row = {
      ...row,
      activeButton,
      labelButton,
      action,
    };
    return row;
  };

  truncate = (str) => {
    return str.length > 150 ? str.substring(0, 150) + '...' : str;
  };

  goToCall = (myCase) => {
    const { history } = this.props;
    history.push('/cases/call', {
      myCase,
    });
  };

  onAlertClose = () => {
    this.setState({
      ...this.state,
      showAlert: false,
      alertVariant: '',
    });
  };

  setSchedule = () => {
    const { meetings, singleCase } = this.state;
    const payload = {
      case: singleCase.id,
    };

    PortalService.confirmCall(payload).then((response) => {
      this.setState({
        meetings: meetings.map((myCase) => {
          return myCase.id === response.data.case.id
            ? this.calculate(response.data.case)
            : myCase;
        }),
        showModal: false,
        singleCase: '',
        alertVariant: 'success',
        showAlert: true,
      });
    });
  };

  accept = (singleCase) => {
    this.setState({
      showModal: true,
      singleCase,
    });
  };

  confirmModal = () => {
    const { showModal, singleCase } = this.state;
    const { profile } = this.context;
    return (
      <ConfirmationModal
        show={showModal}
        type={profile.personType.id}
        onHide={() => {
          this.setState({ showModal: false });
        }}
        singleCase={singleCase}
        schedule={singleCase.schedule}
        onSchedule={this.setSchedule}
      />
    );
  };

  deleteCase = (id) => {
    PortalService.deleteCase(id).then((_response) => {
      this.fetchCases();
    });
  };

  render() {
    const {
      showAlert,
      alertVariant,
      singleCase,
      meetings,
      emptyDataSet,
    } = this.state;

    let alert = (
      <AlertDismissible
        show={showAlert}
        variant={alertVariant}
        onAlertClose={this.onAlertClose}
      />
    );

    if (!showAlert || alertVariant === '') {
      alert = '';
    }

    return (
      <Container className="dashboard">
        {alert}
        {singleCase ? this.confirmModal() : <></>}
        <div className="box" style={{ boxShadow: 'none' }}>
          <div className="box-header mb-5">
            <h2>Mis solicitudes</h2>
          </div>
          <div className="box-content">
            {meetings.length === 0 && emptyDataSet ? (
              <EmptyDataSet />
            ) : (
              <Row>
                {meetings.map((row) => {
                  return (
                    <Col
                      xs={12}
                      sm={12}
                      lg={4}
                      className="mb-4"
                      key={`case-${row.id}`}
                    >
                      <Card className="animate__fadeIn animate__animated">
                        <Card.Body>
                          <Card.Title>{row.summary}</Card.Title>
                          <Card.Text
                            style={{
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }}
                          >
                            {this.truncate(row.description)}
                          </Card.Text>
                          <Row style={{ marginBottom: 0 }}>
                            <Col>
                              <Card.Link>
                                <FontAwesomeIcon icon={faUserCircle} />
                                Yo, {row.owner.fullName}
                              </Card.Link>
                            </Col>
                            <Col className="text-right">
                              <Card.Link>
                                <FontAwesomeIcon icon={faCompass} />
                                {row.owner.country.name}
                              </Card.Link>
                            </Col>
                          </Row>
                        </Card.Body>
                        <Card.Footer>
                          {row.schedule.scheduleStatus.id === 2 &&
                          row.activeButton ? (
                            <>
                              <Button
                                variant="primary"
                                block
                                onClick={() => {
                                  row.activeButton ? this.accept(row) : '';
                                }}
                                disabled={!row.activeButton}
                              >
                                {row.labelButton}
                              </Button>
                              <Button
                                variant="secondary"
                                block
                                onClick={() => {
                                  row.activeButton ? this.reject(row.id) : '';
                                }}
                              >
                                Rechazar
                              </Button>
                            </>
                          ) : (
                            <>
                              <Button
                                variant="primary"
                                block
                                onClick={() => {
                                  row.action(row.id);
                                }}
                                disabled={!row.activeButton}
                              >
                                {row.labelButton}
                              </Button>
                              <Button
                                variant="link"
                                block
                                onClick={() => {
                                  this.deleteCase(row.id);
                                }}
                              >
                                Cancelar
                              </Button>
                            </>
                          )}
                        </Card.Footer>
                      </Card>
                    </Col>
                  );
                })}
              </Row>
            )}
          </div>
        </div>
      </Container>
    );
  }
}
Cases.contextType = ProfileContext;
export default withRouter(Cases);
