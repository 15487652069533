import React from 'react';
import { withRouter } from 'react-router-dom';

import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import LoginService from './LoginService';
import Captcha from './../../common/Captcha';
import AlertDissmisible from './../../common/AlertDismissible';

class Login extends React.Component {
  state = {
    inputs: {
      email: '',
      password: '',
      'g-recaptcha-response': null,
    },
    errors: {
      email: false,
      password: false,
      'g-recaptcha-response': null,
    },
    isLoading: false,
    showAlert: false,
    alertVariant: '',
  };

  captchaRef = React.createRef();

  onSubmit = (event) => {
    event.preventDefault();
    const { inputs } = this.state;
    this.setState({
      isLoading: true,
    });
    LoginService.login(inputs).then(
      (response) => {
        localStorage.setItem('token', response.data.token);
        window.location = '/';
      },
      (_error) => {
        this.captchaRef.current.props.grecaptcha.reset();
        this.setState({
          isLoading: false,
          alertVariant: 'info',
          showAlert: true,
        });
      }
    );
  };

  onChange = (e) => {
    this.setState({
      inputs: {
        ...this.state.inputs,
        [e.target.id]: e.target.value,
      },
      errors: {
        ...this.state.errors,
        [e.target.id]: false,
      },
    });
  };

  onBlur = (e) => {
    const { inputs } = this.state;
    if (inputs[e.target.id].length === 0) {
      this.setState({
        errors: {
          ...this.state.errors,
          [e.target.id]: true,
        },
      });
    }
  };

  recaptchaOnChange = (state) => {
    this.setState(state);
  };

  recaptchaExpired = (state) => {
    this.setState(state);
  };

  onAlertClose = () => {
    this.setState({
      showAlert: false,
      alertVariant: '',
    });
  };

  render() {
    const { inputs, errors, isLoading, showAlert, alertVariant } = this.state;
    let alert = (
      <AlertDissmisible
        show={showAlert}
        variant={alertVariant}
        onAlertClose={this.onAlertClose}
      />
    );

    if (!showAlert || alertVariant === '') {
      alert = '';
    }
    return (
      <Container>
        {alert}
        <Row>
          <Col md={{ span: 6, offset: 3 }}>
            <div className="box">
              <div className="box-content p-3">
                <Form onSubmit={this.onSubmit}>
                  <Row>
                    <Col xs={12} md={12} lg={12}>
                      <Form.Group controlId="email">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          onChange={this.onChange}
                          onBlur={this.onBlur}
                          required
                          type="email"
                          placeholder="Email"
                          value={inputs.email}
                          className={!errors.email ? 'valid' : 'invalid'}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={12} lg={12}>
                      <Form.Group controlId="password">
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                          onChange={this.onChange}
                          onBlur={this.onBlur}
                          required
                          type="password"
                          placeholder="Password"
                          value={inputs.password}
                          className={!errors.password ? 'valid' : 'invalid'}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="mb-3 text-center">
                    <Col xs={12} md={12} lg={12}>
                      <Captcha
                        captchaRef={this.captchaRef}
                        recaptchaOnChange={this.recaptchaOnChange}
                        recaptchaExpired={this.recaptchaExpired}
                        inputs={inputs}
                        errors={errors}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Button
                        variant="primary"
                        size="lg"
                        type="submit"
                        block
                        disabled={isLoading}
                      >
                        {isLoading ? 'Validando credenciales...' : 'Ingresar'}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withRouter(Login);
