import React from 'react';
import { Row, Col, Button, Container } from 'react-bootstrap';
import Menu from './../../common/Menu/Demo';
import JitsiMeet from './../../common/JitsiMeet';
import Form from './Form';

class Demo extends React.Component {
  state = {
    profile: {
      fullName: 'Ignacio Opazo',
      email: 'iopazog@gmail.com',
    },
    roomName: 'Prueba',
    showForm: false,
  };

  render() {
    const { profile, roomName, showForm } = this.state;
    return (
      <>
        <Menu />
        <main
          role="main"
          className="inner cover animate__animated animate__fadeIn"
        >
          <h1 className="cover-heading">Homy</h1>
          <p className="lead">
            Homy es una plataforma multidispositivo de videollamadas totalmente
            customizable que permite la comunicación online para diferentes
            organizaciones, empresas, gobiernos e instituciones.
          </p>
          {showForm ? (
            <Container>
              <Row className="animate__animated animate__fadeInUp">
                <Col xs={12} sm={12} lg={12} md={12}>
                  <Form />
                </Col>
              </Row>
            </Container>
          ) : (
            <>
              <p className="lead">
                <Button
                  variant="secondary"
                  size="lg"
                  onClick={() => {
                    this.setState({
                      showForm: !showForm,
                    });
                  }}
                >
                  Comenzar
                </Button>
              </p>
            </>
          )}

          {/* <JitsiMeet profile={profile} roomName={roomName} /> */}
        </main>
        <footer className="mastfoot mt-auto">
          <div className="inner">
            <p>Copyright Homy 2020</p>
          </div>
        </footer>
      </>
    );
  }
}

export default Demo;
