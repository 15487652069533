import React from 'react';
import { withRouter } from 'react-router-dom';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import AlertDissmisible from './../../common/AlertDismissible';
import SettingsService from './SettingsService';
import ProfilePicture from 'profile-picture';
import 'profile-picture/build/ProfilePicture.css';
import { ProfileContext } from './../../app/Context/ProfileContext';

class Settings extends React.Component {
  profilePictureRef = React.createRef();

  state = {
    inputs: {
      confirmPassword: '',
      password: '',
    },
    errors: {
      confirmPassword: false,
      password: false,
    },
    isLoading: false,
    showAlert: false,
    alertVariant: '',
    validForm: false,
    disabledAvatarButton: true,
    userData: this.props.user,
  };

  onSubmit = (event) => {
    event.preventDefault();
    const { inputs } = this.state;
    this.setState({
      isLoading: true,
    });

    SettingsService.changePassword(inputs).then(
      (_response) => {
        this.setState({
          inputs: {
            confirmPassword: '',
            password: '',
          },
          errors: {
            confirmPassword: false,
            password: false,
          },
          isLoading: false,
          alertVariant: 'success',
          showAlert: true,
          validForm: false,
        });
      },
      (_error) => {
        this.setState({
          inputs: {
            confirmPassword: '',
            password: '',
          },
          errors: {
            confirmPassword: false,
            password: false,
          },
          isLoading: false,
          alertVariant: 'info',
          showAlert: true,
          validForm: false,
        });
      }
    );
  };

  onChange = (e) => {
    this.setState({
      inputs: {
        ...this.state.inputs,
        [e.target.name]: e.target.value,
      },
      errors: {
        ...this.state.errors,
        [e.target.name]: false,
      },
    });
  };

  handleOnBlur = (e) => {
    const { inputs } = this.state;

    this.setState({
      errors: {
        password: inputs.password !== inputs.confirmPassword ? true : false,
        confirmPassword:
          inputs.password !== inputs.confirmPassword ? true : false,
      },
      validForm: inputs.password === inputs.confirmPassword ? true : false,
    });
  };

  onAlertClose = () => {
    this.setState({
      showAlert: false,
      alertVariant: '',
    });
  };

  handleUpload = () => {
    const { profile } = this.context;
    const PP = this.profilePictureRef.current;
    const avatar = PP.getImageAsDataUrl();

    const payload = {
      avatar,
    };

    SettingsService.updateProfile(payload).then((response) => {
      this.setState(
        {
          showAlert: true,
          alertVariant: 'success',
        },
        () => {
          profile.actions.refreshToken(response.data.token);
        }
      );
    });
  };

  imageListener = () => {
    this.setState({
      disabledAvatarButton: !this.state.disabledAvatarButton,
    });
  };

  render() {
    const { profile } = this.context;
    const {
      inputs,
      errors,
      isLoading,
      showAlert,
      alertVariant,
      validForm,
      disabledAvatarButton,
    } = this.state;

    let alert = (
      <AlertDissmisible
        show={showAlert}
        variant={alertVariant}
        onAlertClose={this.onAlertClose}
      />
    );

    if (!showAlert || alertVariant === '') {
      alert = '';
    }
    return (
      <Container>
        {alert}
        <Row>
          <Col lg={12} sm={12} md={8} className="mt-3">
            <h1>Hola {profile.fullName}!</h1>
          </Col>
        </Row>
        <Row>
          <Col lg={4} md={4} sm={12}>
            <div className="box">
              <div className="box-header p-3">
                <h2>Cambiar mi foto</h2>
              </div>
              <div className="box-content text-center p-3">
                <ProfilePicture
                  ref={this.profilePictureRef}
                  useHelper={true}
                  debug={false}
                  frameFormat="circle"
                  maxZoom={0.4}
                  maxImageSize="400"
                  minImageSize="100"
                  onImageLoaded={this.imageListener}
                  onImageRemoved={this.imageListener}
                />
                <Button
                  variant="outline-primary"
                  size="sm"
                  type="button"
                  onClick={this.handleUpload}
                  disabled={disabledAvatarButton}
                >
                  Confirmar
                </Button>
              </div>
            </div>
          </Col>
          <Col lg={8} md={8} sm={12}>
            <div className="box">
              <div className="box-header p-3">
                <h2>Cambio de contraseña</h2>
                <p>Debes ingresar una nueva contraseña en el formulario</p>
              </div>
              <div className="box-content p-3">
                <Form onSubmit={this.onSubmit}>
                  <Row>
                    <Col xs={12} md={12} lg={12}>
                      <Form.Group controlId="password">
                        <Form.Label>Ingresa tu nueva contraseña</Form.Label>
                        <Form.Control
                          onChange={this.onChange}
                          onBlur={this.handleOnBlur}
                          required
                          name="password"
                          type="password"
                          placeholder="Ingresa una nueva contraseña"
                          value={inputs.password}
                          className={!errors.password ? 'valid' : 'invalid'}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={12} lg={12}>
                      <Form.Group controlId="confirmPassword">
                        <Form.Label>Repite la contraseña</Form.Label>
                        <Form.Control
                          onChange={this.onChange}
                          onBlur={this.handleOnBlur}
                          required
                          name="confirmPassword"
                          type="password"
                          placeholder="Confirma la contraseña"
                          value={inputs.confirmPassword}
                          className={
                            !errors.confirmPassword ? 'valid' : 'invalid'
                          }
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Button
                        variant="primary"
                        size="lg"
                        type="submit"
                        block
                        disabled={isLoading || !validForm}
                      >
                        {isLoading ? 'Cargando...' : 'Cambiar'}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

Settings.contextType = ProfileContext;

export default withRouter(Settings);
