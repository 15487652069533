import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import Menu from '../../../common/Menu';
import Router from './../../Router';
import Footer from '../../../common/Footer';

const Classic = () => {
  return (
    <BrowserRouter>
      <Container className="h-100 pt-3 pb-3 mx-auto" fluid>
        <Menu />
        <Router />
      </Container>
      <Footer />
    </BrowserRouter>
  );
};

export default Classic;
