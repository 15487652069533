import React from 'react';
import { withRouter } from 'react-router-dom';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import PortalService from './../PortalService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle, faCompass } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment-timezone';
import EmptyDataSet from './../../../common/EmptyDataSet';

class Meetings extends React.Component {
  state = {
    meetings: [],
    emptyDataSet: false,
  };

  componentDidMount = () => {
    this.fetchMeetings();
  };

  fetchMeetings = () => {
    PortalService.mycalls().then((response) => {
      const meetings = response.data.sort((a, b) => {
        if (a.schedule.endTime < b.schedule.endTime) {
          return 1;
        }
        if (a.schedule.endTime > b.schedule.endTime) {
          return -1;
        }
        return 0;
      });
      this.setState({
        ...this.state,
        meetings: meetings.map((row) => {
          return this.calculate(row);
        }),
      });

      setTimeout(() => {
        this.setState({
          emptyDataSet: true,
        });
      }, 200);
    });
  };

  calculate = (row) => {
    let now = moment().tz('America/Santiago').unix();
    let startTime = moment(row.schedule.startTime, 'X')
      .tz('America/Santiago')
      .format('YYYY-MM-DD HH:mm');

    let labelButton = '';
    let activeButton = false;

    if (row.schedule.scheduleStatus.id === 2) {
      if (now > row.schedule.endTime) {
        labelButton = 'Invitacion vencida';
      } else {
        labelButton = 'No aceptada aún';
      }
    } else {
      if (now > row.schedule.endTime) {
        labelButton = 'Finalizada';
      } else {
        const nowFormat = moment(moment(now, 'X').format('YYYY-MM-DD HH:mm'));
        var hours = nowFormat.diff(startTime, 'hours');
        var minutes = hours / 24;
        var timeArray = Math.abs(minutes).toString().split('.');

        if (hours < 0) {
          labelButton = `Faltan ${timeArray[0]} dias y ${Math.abs(
            hours
          )} horas para acceder`;
          activeButton = false;
        } else {
          labelButton = 'Entrar';
          activeButton = true;
        }
      }
    }
    row = {
      ...row,
      activeButton,
      labelButton,
    };
    return row;
  };

  truncate = (str) => {
    return str.length > 150 ? str.substring(0, 150) + '...' : str;
  };

  goToCall = (myCase) => {
    const { history } = this.props;
    history.push('/meetings/call', {
      myCase,
    });
  };

  render() {
    const { meetings, emptyDataSet } = this.state;

    return (
      <Container className="dashboard">
        <div className="box" style={{ boxShadow: 'none' }}>
          <div className="box-header mb-5">
            <h2>Mis próximas reuniones</h2>
          </div>
          <div className="box-content">
            {meetings.length === 0 && emptyDataSet ? (
              <EmptyDataSet />
            ) : (
              <Row>
                {meetings.map((row) => {
                  return (
                    <Col
                      xs={12}
                      sm={12}
                      lg={4}
                      className="mb-4"
                      key={`case-${row.id}`}
                    >
                      <Card className="animate__fadeIn animate__animated">
                        <Card.Body>
                          <Card.Title>{row.summary}</Card.Title>
                          <Card.Text
                            style={{
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }}
                          >
                            {this.truncate(row.description)}
                          </Card.Text>
                          <Row style={{ marginBottom: 0 }}>
                            <Col>
                              <Card.Link>
                                <FontAwesomeIcon icon={faUserCircle} />
                                {row.owner.fullName}
                              </Card.Link>
                            </Col>
                            <Col className="text-right">
                              <Card.Link>
                                <FontAwesomeIcon icon={faCompass} />
                                {row.owner.country.name}
                              </Card.Link>
                            </Col>
                          </Row>
                        </Card.Body>
                        <Card.Footer>
                          <Button
                            variant="primary"
                            block
                            onClick={() => {
                              row.activeButton ? this.goToCall(row.id) : '';
                            }}
                            disabled={!row.activeButton}
                          >
                            {row.labelButton}
                          </Button>
                        </Card.Footer>
                      </Card>
                    </Col>
                  );
                })}
              </Row>
            )}
          </div>
        </div>
      </Container>
    );
  }
}

export default withRouter(Meetings);
