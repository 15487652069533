import React from 'react';
import { Carousel } from 'react-bootstrap';
import emprender1 from './../../assets/img/emprender1.jpg';
import emprender2 from './../../assets/img/emprender2.jpg';

const HeaderCarousel = () => {
  return (
    <Carousel fade interval={10000}>
      <Carousel.Item className="text-center">
        <img className="w-100" src={emprender1} alt="Converge con Emprender" />
        <Carousel.Caption>
          <h3>Ingresa a Converge!</h3>
          <p>Únete al espacio colaborativo de Converge</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item className="text-center">
        <img className="w-100" src={emprender2} alt="Converge con Emprender" />
        <Carousel.Caption>
          <h3>Ingresa a Converge!</h3>
          <p>Únete al espacio colaborativo de Converge</p>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
};

export default HeaderCarousel;
