import request from './../../../utilities/request';

function newCase(payload) {
  return request({
    url: '/case/create',
    method: 'POST',
    data: payload,
  });
}

const SolicitanteService = {
  newCase,
};

export default SolicitanteService;
