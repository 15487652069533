import request from './../../utilities/request';

function changePassword(payload) {
  return request({
    url: '/person/changePassword',
    method: 'POST',
    data: payload,
  });
}

function updateProfile(payload) {
  return request({
    url: '/person/update',
    method: 'POST',
    data: payload,
  });
}

const SettingsService = {
  changePassword,
  updateProfile,
};

export default SettingsService;
