import React from 'react';
import { Form, Row, Col, Button } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { horas } from './../../../../utilities/String';
import moment from 'moment-timezone';

class ScheduleForm extends React.Component {
  state = {
    creationDate: new Date(),
    time: '',
    schedule: { startTime: '', endTime: '' },
  };

  timeChange = (event) => {
    this.setState(
      {
        time: event.target.value,
      },
      () => {
        this.createSchedule();
      }
    );
  };

  handleChangeDate = (value) => {
    const { time } = this.state;
    this.setState(
      {
        creationDate: value,
      },
      () => {
        if (time) {
          this.createSchedule();
        }
      }
    );
  };

  createSchedule = () => {
    const { creationDate, time } = this.state;
    if (time !== '') {
      const selectedHour = horas().find((hora) => {
        return hora.key === time;
      });

      let date = moment(creationDate).format('YYYY-MM-DD');
      let timeFromString = ''.concat(date).concat(' ').concat(time);
      const format = `YYYY-MM-DD HH:mm ${selectedHour.ap}`;
      let startTime = moment(timeFromString, format)
        .tz('America/Santiago')
        .format('X');
      let endTime = moment(timeFromString, format)
        .add(1, 'hours')
        .tz('America/Santiago')
        .format('X');

      this.setState(
        {
          ...this.state,
          schedule: {
            startTime,
            endTime,
          },
        },
        () => {
          this.props.getSchedule(this.state.schedule);
        }
      );
    }
  };

  onSubmit = (event) => {
    event.preventDefault();
    this.props.onSubmit();
  };

  render() {
    const { creationDate, time } = this.state;
    const {
      singleCase: {
        owner,
        schedule: { scheduleStatus },
      },
    } = this.props;
    return (
      <Form onSubmit={this.onSubmit}>
        <Row style={{ marginBottom: 0 }}>
          <Col xs={12} md={12} lg={12}>
            <Form.Group>
              <Form.Label>Seleccione una fecha</Form.Label>
              <DatePicker
                selected={creationDate}
                onChange={(date) => {
                  this.handleChangeDate(date);
                }}
                className="form-control"
                dateFormat="dd-MM-yyyy"
                disabled={scheduleStatus.id !== 1}
              />
            </Form.Group>
          </Col>
          <Col xs={12} md={12} lg={12}>
            <Form.Group>
              <Form.Label>Seleccione un horario de inicio</Form.Label>
              <Form.Control
                name="time"
                onChange={this.timeChange}
                required
                as="select"
                disabled={scheduleStatus.id !== 1}
              >
                <option value="">Selecciona una opción</option>
                {horas().map((hora) => {
                  return (
                    <option key={`hora-${hora.key}`} value={hora.key}>
                      {hora.value}
                    </option>
                  );
                })}
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col lg={10}>
            <p>
              Enviaremos un correo a {owner.fullName} para que puedan conectarse
              a la llamada
            </p>
          </Col>
          <Col>
            <Button
              variant="primary"
              size="lg"
              type="submit"
              block
              disabled={
                (time && creationDate) || scheduleStatus.id !== 1 ? false : true
              }
            >
              Enviar invitación
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }
}

export default ScheduleForm;
