import axios from 'axios';

/**
 * Create an Axios Client with defaults
 */
const client = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

/**
 * Request Wrapper with default success/error actions
 */
const request = function (options) {
  let token = localStorage.getItem('token');
  if (token) {
    options.headers = { Authorization: `Bearer ${token}` };
  }

  const onSuccess = function (response) {
    return response;
  };

  const onError = function (error) {
    if (error.response.status && error.response.status === 401) {
      localStorage.removeItem('token');
    }
    return Promise.reject(error.response || error.message);
  };

  return client(options).then(onSuccess).catch(onError);
};

export default request;
