import React, { useContext } from 'react';
import { Nav, Navbar, NavDropdown, Image } from 'react-bootstrap';
import { NavLink, Link, withRouter } from 'react-router-dom';
import logo from './../../assets/img/logo.svg';
import { isLogin, logout } from './../../utilities/isLogin';
import { ProfileContext } from './../../app/Context/ProfileContext';

const Menu = (props) => {
  const { profile, role } = useContext(ProfileContext);
  const {
    history: { location },
  } = props;

  if (location.pathname === '/home') {
    return (
      <Navbar expand="lg">
        <Navbar.Brand href="/">
          <img
            src={logo}
            height={80}
            className="d-inline-block align-top"
            alt="Converge"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        {isLogin() ? (
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto"></Nav>
            <Nav>
              <NavDropdown
                title={
                  <>
                    <Image
                      src={profile.avatar}
                      width="30"
                      height="30"
                      className="mr-1"
                      roundedCircle
                    />
                    {profile.fullName}
                  </>
                }
                id="nav-dropdown"
              >
                <NavDropdown.Item
                  onClick={() => {
                    logout();
                    window.location = '/';
                  }}
                >
                  Salir
                </NavDropdown.Item>
                <NavDropdown.Item
                  onClick={() => {
                    props.history.push('/settings');
                  }}
                >
                  Configuraciones
                </NavDropdown.Item>
              </NavDropdown>
              <p>{profile.email}</p>
            </Nav>
          </Navbar.Collapse>
        ) : (
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              <Nav.Item>
                <NavLink
                  exact={true}
                  to="/que-es-converge"
                  className="nav-link"
                  activeClassName="active"
                >
                  ¿Qué es Converge?
                </NavLink>
              </Nav.Item>
              <Nav.Item>
                <NavLink
                  exact={true}
                  to="/como-funciona"
                  className="nav-link"
                  activeClassName="active"
                >
                  ¿Cómo funciona?
                </NavLink>
              </Nav.Item>
              <Nav.Item>
                <NavLink
                  exact={true}
                  className="nav-link"
                  to="/reportar-consulta"
                >
                  Reporta tu consulta
                </NavLink>
              </Nav.Item>
            </Nav>
            <Nav>
              <Link to="/signin" className="btn btn-primary">
                Ingresar
              </Link>
            </Nav>
          </Navbar.Collapse>
        )}
      </Navbar>
    );
  } else {
    return (
      <Navbar expand="lg">
        <Navbar.Brand href="/">
          <img
            src={logo}
            height={80}
            className="d-inline-block align-top"
            alt="Converge"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        {isLogin() ? (
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              <Nav.Item>
                <NavLink
                  exact
                  to="/home"
                  className="nav-link"
                  activeClassName="active"
                >
                  Home
                </NavLink>
              </Nav.Item>
              {role === 1 ? (
                <>
                  <Nav.Item>
                    <NavLink
                      to="/dashboard"
                      className="nav-link"
                      activeClassName="active"
                    >
                      Match
                    </NavLink>
                  </Nav.Item>
                  <Nav.Item>
                    <NavLink
                      className="nav-link"
                      activeClassName="active"
                      to={'/meetings'}
                    >
                      Mis reuniones
                    </NavLink>
                  </Nav.Item>
                </>
              ) : role === 2 ? (
                <>
                  <Nav.Item>
                    <NavLink
                      className="nav-link"
                      activeClassName="active"
                      exact
                      to="/cases"
                    >
                      Mis Solicitudes
                    </NavLink>
                  </Nav.Item>
                  <Nav.Item>
                    <NavLink
                      exact
                      to="/cases/new"
                      className="nav-link"
                      activeClassName="active"
                    >
                      Nueva Solicitud
                    </NavLink>
                  </Nav.Item>
                </>
              ) : (
                <></>
              )}
              {process.env.REACT_APP_WITH_PAGES ? (
                <Nav.Item>
                  <NavLink
                    to="/capsules"
                    className="nav-link"
                    activeClassName="active"
                  >
                    Biblioteca Virtual
                  </NavLink>
                </Nav.Item>
              ) : (
                <></>
              )}
            </Nav>
            <Nav>
              <NavDropdown
                title={
                  <>
                    <Image
                      src={profile.avatar}
                      width="30"
                      height="30"
                      className="mr-1"
                      roundedCircle
                    />
                    {profile.fullName}
                  </>
                }
                id="nav-dropdown"
              >
                <NavDropdown.Item
                  onClick={() => {
                    logout();
                    window.location = '/';
                  }}
                >
                  Salir
                </NavDropdown.Item>
                <NavDropdown.Item
                  onClick={() => {
                    props.history.push('/settings');
                  }}
                >
                  Configuraciones
                </NavDropdown.Item>
              </NavDropdown>
              <p>{profile.email}</p>
            </Nav>
          </Navbar.Collapse>
        ) : (
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              <Nav.Item>
                <NavLink
                  exact={true}
                  to="/que-es-converge"
                  className="nav-link"
                  activeClassName="active"
                >
                  ¿Qué es Converge?
                </NavLink>
              </Nav.Item>
              <Nav.Item>
                <NavLink
                  exact={true}
                  to="/como-funciona"
                  className="nav-link"
                  activeClassName="active"
                >
                  ¿Cómo funciona?
                </NavLink>
              </Nav.Item>
              <Nav.Item>
                <NavLink
                  exact={true}
                  className="nav-link"
                  to="/reportar-consulta"
                >
                  Reporta tu consulta
                </NavLink>
              </Nav.Item>
            </Nav>
            <Nav>
              <Link to="/signin" className="btn btn-primary">
                Ingresar
              </Link>
            </Nav>
          </Navbar.Collapse>
        )}
      </Navbar>
    );
  }
};

export default withRouter(Menu);
