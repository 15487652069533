import React from 'react';
import { Container, Row, Col, Card, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import WordpressService from './../WordpressService';
import defaultImg from './../../../assets/img/default-post.jpg';
import moment from 'moment';
import 'moment/locale/es';
import EmptyDataSet from './../../../common/EmptyDataSet';
import Skeleton from 'react-loading-skeleton';

class Capsules extends React.Component {
  state = {
    isLoading: true,
    categories: [],
    posts: [],
    filteredPost: [],
    selectedCategory: '',
  };

  async componentDidMount() {
    moment.locale('es');
    let response = await WordpressService.categories();
    this.setState({
      isLoading: false,
      categories: response.data,
    });
    this.fetchPosts();
  }

  async fetchPosts() {
    const { selectedCategory } = this.state;
    let response = await WordpressService.posts(selectedCategory);
    let pPosts = response.data.map(async (post) => {
      post.thumbnail = defaultImg;
      if (post._links['wp:featuredmedia']) {
        let embeedUrl = post._links['wp:featuredmedia'][0].href;
        let embeedResponse = await fetch(embeedUrl);
        let data = await embeedResponse.json();
        post.thumbnail = data.media_details.sizes.thumbnail.source_url;
      }
      return post;
    });
    const posts = await Promise.all(pPosts);
    this.setState({
      posts,
      filteredPost: posts,
    });
  }

  selectCategory = (key) => {
    const { posts } = this.state;
    const parseKey = parseInt(key);
    let filteredPost = posts;

    if (key) {
      filteredPost = posts.filter((post) => {
        if (post.categories.indexOf(parseKey) !== -1) {
          return post;
        }
      });
    }
    this.setState({
      ...this.state,
      filteredPost,
      selectedCategory: key,
    });
  };

  render() {
    const { selectedCategory, categories, filteredPost } = this.state;
    return (
      <Container className="dashboard mt-5">
        <h1>Biblioteca Virtual</h1>
        <Row>
          <Col>
            <Nav activeKey={selectedCategory} onSelect={this.selectCategory}>
              <Nav.Item key="key-all">
                <Nav.Link eventKey="">Todas</Nav.Link>
              </Nav.Item>
              {categories.map((cat) => {
                return (
                  <Nav.Item key={`key-${cat.id}`}>
                    <Nav.Link eventKey={cat.id}>{cat.name}</Nav.Link>
                  </Nav.Item>
                );
              })}
            </Nav>
          </Col>
        </Row>
        {filteredPost.length === 0 ? (
          <Row>
            {[...Array(4).keys()].map((i) => {
              return (
                <Col
                  key={`skel-${i}`}
                  xs={12}
                  sm={12}
                  lg={3}
                  md={3}
                  className="mb-4"
                >
                  <Skeleton height={245} />
                </Col>
              );
            })}
          </Row>
        ) : (
          <Row>
            {filteredPost.map((row) => {
              return (
                <Col
                  xs={12}
                  sm={12}
                  lg={3}
                  md={3}
                  className="mb-4"
                  key={`post-${row.id}`}
                >
                  <Link to={`/capsules/post/${row.id}/${row.slug}`}>
                    <Card className="animate__fadeIn animate__animated">
                      <Card.Img variant="top" src={row.thumbnail} />
                      <Card.ImgOverlay>
                        <Card.Title>{row.title.rendered}</Card.Title>
                        <Card.Text>
                          hace {moment(row.date).fromNow(true)}
                        </Card.Text>
                      </Card.ImgOverlay>
                      <span className="gradient"></span>
                    </Card>
                  </Link>
                </Col>
              );
            })}
          </Row>
        )}
      </Container>
    );
  }
}

export default Capsules;
