import React from 'react';

const Menu = () => {
  return (
    <header className="masthead mb-auto">
      <div className="inner">
        <h3 className="masthead-brand">Homy</h3>
        <nav className="nav nav-masthead justify-content-center">
          <a className="nav-link active" href="#">
            Home
          </a>
          <a className="nav-link" href="#">
            Features
          </a>
          <a className="nav-link" href="#">
            Contacto
          </a>
        </nav>
      </div>
    </header>
  );
};

export default Menu;
