import React, { useState, useEffect } from 'react';
import { ProgressBar } from 'react-bootstrap';

const JitsiMeet = (props) => {
  const [loading, setLoading] = useState(true);
  const [percent, setPercent] = useState(0);

  const containerStyle = {
    width: '100%',
    height: '400px',
  };

  const jitsiContainerStyle = {
    display: loading ? 'none' : 'block',
    width: '100%',
    height: '100%',
  };

  const password = process.env.REACT_APP_JITSI_PASS;

  function startConference() {
    try {
      const domain = 'meet.moveapps.cl';
      const roomName = props.roomName || `ConvergeRoom-${props.myCase.id}`;
      const options = {
        roomName: roomName,
        height: 400,
        parentNode: document.getElementById('jitsi-container'),
        enableClosePage: true,
        userInfo: {
          email: props.profile.email,
          displayName: props.profile.fullName,
        },
        interfaceConfigOverwrite: {
          DEFAULT_REMOTE_DISPLAY_NAME: 'Bienvenido',
          DEFAULT_LOCAL_DISPLAY_NAME: 'me',
          JITSI_WATERMARK_LINK: props.link || 'https://www.convergeapp.cl',
          SHOW_WATERMARK_FOR_GUESTS: false,
          SHOW_JITSI_WATERMARK: false,
          JITSI_WATERMARK_LINK: props.link || 'https://www.convergeapp.cl',
          TOOLBAR_BUTTONS: [
            'microphone',
            'camera',
            'desktop',
            'fullscreen',
            'hangup',
            'profile',
            'raisehand',
            'tileview',
          ],
        },
      };

      const api = new JitsiMeetExternalAPI(domain, options);

      api.addEventListener('passwordRequired', () => {
        api.executeCommand('password', password);
      });

      api.addEventListener('videoConferenceJoined', () => {
        setPercent(100);
        setTimeout(() => {
          setLoading(false);
        }, 500);

        setTimeout(() => {
          api.executeCommand('password', password);
        }, 2000);
      });

      api.on('readyToClose', () => {
        props.showSurvey(true);
      });
    } catch (error) {
      console.error('Failed to load Jitsi API', error);
    }
  }

  useEffect(() => {
    // verify the JitsiMeetExternalAPI constructor is added to the global..
    if (window.JitsiMeetExternalAPI) startConference();
    else alert('Jitsi Meet API script not loaded');
  }, []);

  return (
    <div style={containerStyle}>
      {loading && <ProgressBar animated now={percent} />}
      <div id="jitsi-container" style={jitsiContainerStyle} />
    </div>
  );
};
export default JitsiMeet;
