import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import moment from 'moment-timezone';

function ConfirmationModal(props) {
  const date = moment
    .unix(props.schedule.startTime)
    .tz('America/Santiago')
    .format('DD/MM/YYYY');
  const time = moment
    .unix(props.schedule.startTime)
    .tz('America/Santiago')
    .format('HH:mma');

  const name =
    props.type === 1
      ? props.singleCase.owner.fullName
      : props.singleCase.schedule.volunteer.fullName;
  return (
    <Modal
      show={props.show}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Confirmar Reunión
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Vas a confirmar una cita con <strong>{name}</strong> el: <br />
          <strong>
            {date} a las {time}
          </strong>{' '}
          con una duración máxima de 1 hora.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onSchedule}>Si, Confirmo</Button>
        <Button onClick={props.onHide} variant="secondary">
          Cancelar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ConfirmationModal;
