import React from 'react';
import Home from './../../domain/Home';
import PublicPage from './../../domain/Home/Public/index';
import Registro from './../../domain/Voluntario/Registro';
import Login from './../../domain/Login';
import Dashboard from './../../domain/Portal/Dashboard';
import Case from './../../domain/Portal/Case';
import Meetings from './../../domain/Portal/Meetings';
import VideoCall from './../../domain/Portal/Meetings/VideoCall';
import Settings from './../../domain/Settings';
import Cases from './../../domain/Portal/Cases';
import RegistroSolicitante from './../../domain/Solicitante/RegistroSolicitante';
import NewCase from './../../domain/Portal/Cases/NewCase';
import Capsules from './../../domain/Content/Capsules';
import PostDetail from './../../domain/Content/PostDetail';
import PrivateHome from './../../domain/Home/Private';
import Demo from './../../domain/Demo';

const classic = [
  {
    component: Home,
    isPublic: true,
    restricted: true,
    path: '/',
    exact: true,
  },
  {
    component: () => {
      return <PublicPage id={32} />;
    },
    isPublic: true,
    restricted: true,
    path: '/como-funciona',
    exact: true,
  },
  {
    component: () => {
      return <PublicPage id={50} />;
    },
    isPublic: true,
    restricted: true,
    path: '/que-es-converge',
    exact: true,
  },
  {
    component: Registro,
    isPublic: true,
    restricted: true,
    path: '/signup',
    exact: true,
  },
  {
    component: PrivateHome,
    isPublic: false,
    restricted: false,
    path: '/home',
    exact: true,
  },
  {
    component: Login,
    isPublic: true,
    restricted: true,
    path: '/signin',
    exact: true,
  },
  {
    component: Dashboard,
    isPublic: false,
    restricted: false,
    path: '/dashboard',
    exact: true,
  },
  {
    component: Case,
    isPublic: false,
    restricted: false,
    path: '/dashboard/view-case',
    exact: true,
  },
  {
    component: Meetings,
    isPublic: false,
    restricted: false,
    path: '/meetings',
    exact: true,
  },
  {
    component: VideoCall,
    isPublic: false,
    restricted: false,
    path: '/meetings/call',
    exact: true,
  },
  {
    component: Settings,
    isPublic: false,
    restricted: false,
    path: '/settings',
    exact: true,
  },
  {
    component: Cases,
    isPublic: false,
    restricted: false,
    path: '/cases',
    exact: true,
  },
  {
    component: VideoCall,
    isPublic: false,
    restricted: false,
    path: '/cases/call',
    exact: true,
  },
  {
    component: Capsules,
    isPublic: false,
    restricted: false,
    path: '/capsules',
    exact: true,
  },
  {
    component: PostDetail,
    isPublic: false,
    restricted: false,
    path: '/capsules/post/:id/:slug',
    exact: true,
  },
  {
    component: RegistroSolicitante,
    isPublic: true,
    restricted: false,
    path: '/reportar-consulta',
    exact: true,
  },
  {
    component: NewCase,
    isPublic: false,
    restricted: false,
    path: '/cases/new',
    exact: true,
  },
];

const demo = [
  {
    component: Demo,
    isPublic: true,
    restricted: true,
    path: '/',
    exact: true,
  },
];

const Routes = {
  demo,
  classic,
};

export default Routes;
