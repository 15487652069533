import React from 'react';
import { withRouter } from 'react-router-dom';
import { Container, Row, Col, Button, Form, ListGroup } from 'react-bootstrap';
import VoluntarioService from './../../../Voluntario/VoluntarioService';
import SolicitanteService from './../SolicitanteService';

class NewCase extends React.Component {
  state = {
    inputs: {
      category: '',
      subcategories: [],
      summary: '',
      description: '',
    },
    errors: {
      category: false,
      subcategories: false,
      summary: false,
      description: false,
    },
    categories: [],
    subCategories: [],
    isLoading: false,
  };

  componentDidMount = () => {
    VoluntarioService.categories().then((response) => {
      this.setState({
        ...this.state,
        categories: response.data,
      });
    });
  };

  fetchSubCategories = (categoryId) => {
    VoluntarioService.subCategories(categoryId).then((response) => {
      this.setState({
        subCategories: response.data,
      });
    });
  };

  onChange = (e) => {
    if (e.target.name === 'category') {
      this.fetchSubCategories(e.target.value);
    }
    this.setState({
      inputs: {
        ...this.state.inputs,
        [e.target.name]: e.target.value,
      },
      errors: {
        ...this.state.errors,
        [e.target.name]: false,
      },
    });
  };

  handleOnBlur = (event) => {
    const { inputs } = this.state;
    if (inputs[event.target.name].length === 0) {
      this.setState({
        errors: {
          ...this.state.errors,
          [event.target.name]: true,
        },
      });
    }
  };

  selectedSubCategories = (id) => {
    let { subcategories } = this.state.inputs;

    if (subcategories.includes(id)) {
      subcategories = subcategories = subcategories.filter((catId) => {
        return catId !== id;
      });
    } else {
      subcategories.push(id);
    }

    this.setState({
      ...this.state,
      inputs: {
        ...this.state.inputs,
        subcategories,
      },
      errors: {
        ...this.state.errors,
        subcategories: subcategories.length > 0 ? false : true,
      },
    });
  };

  onSubmit = (e) => {
    e.preventDefault();
    const { inputs } = this.state;
    const { history } = this.props;

    SolicitanteService.newCase(inputs).then((_response) => {
      history.push('/cases');
    });
  };

  render() {
    const { categories, subCategories, inputs, errors, isLoading } = this.state;
    return (
      <Container>
        <Row>
          <Col lg={{ span: 8, offset: 2 }} md={{ span: 8, offset: 2 }} sm={12}>
            <div className="box p-3">
              <div className="box-header">
                <h2>Crear nueva solicitud</h2>
                <p>
                  Ingresa los datos asociados para subir tu solicitud de manera
                  inmediata para que un Voluntari@ se ponga en contacto contigo
                </p>
              </div>
              <div className="box-content">
                <Form onSubmit={this.onSubmit}>
                  <Row>
                    <Col sm={12} lg={6} md={6}>
                      <Form.Group>
                        <Form.Label>Resumen</Form.Label>
                        <Form.Control
                          name="summary"
                          onChange={this.onChange}
                          onBlur={this.handleOnBlur}
                          required
                          placeholder="Necesito informacion sobre..."
                          className={!errors.summary ? 'valid' : 'invalid'}
                        ></Form.Control>
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>Descripcion</Form.Label>
                        <Form.Control
                          name="description"
                          onChange={this.onChange}
                          onBlur={this.handleOnBlur}
                          required
                          placeholder="Ingresa el detalle de tu solicitud"
                          as="textarea"
                          className={!errors.description ? 'valid' : 'invalid'}
                        ></Form.Control>
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>¿Qué tipo de ayuda buscas?</Form.Label>
                        <Form.Control
                          name="category"
                          onChange={this.onChange}
                          onBlur={this.handleOnBlur}
                          required
                          as="select"
                          className={!errors.category ? 'valid' : 'invalid'}
                        >
                          <option value="">Selecciona una opción</option>
                          {categories.map((category) => {
                            return (
                              <option
                                value={category.id}
                                key={`cat-${category.id}`}
                              >
                                {category.name}
                              </option>
                            );
                          })}
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col sm={12} lg={6} lg={6}>
                      <Form.Group>
                        <Form.Label>Selecciona una categoria</Form.Label>
                        <ListGroup>
                          <ListGroup.Item disabled>
                            Puedes seleccionar mas de una opcion
                          </ListGroup.Item>
                          {subCategories.map((row) => {
                            return (
                              <ListGroup.Item
                                key={`list-${row.id}`}
                                active={
                                  inputs.subcategories.includes(row.id)
                                    ? true
                                    : false
                                }
                                onClick={() => {
                                  this.selectedSubCategories(row.id);
                                }}
                              >
                                {row.name}
                              </ListGroup.Item>
                            );
                          })}
                        </ListGroup>
                      </Form.Group>
                    </Col>
                  </Row>
                  <div className="actions">
                    <Button
                      variant="primary"
                      size="lg"
                      type="submit"
                      disabled={isLoading}
                    >
                      {isLoading ? 'Creando...' : 'Crear'}
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withRouter(NewCase);
