import React from 'react';
import { Container, Jumbotron, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

const EmptyDataSet = () => {
  return (
    <Row>
      <Col sm={12} lg={{ span: 8, offset: 2 }} md={{ span: 8, offset: 2 }}>
        <Jumbotron
          style={{ padding: 0, marginTop: 10 }}
          className="animate__animated animate__slideInUp"
        >
          <Container className="text-center" style={{ color: '#b1b1b1' }}>
            <h1 style={{ fontSize: 120 }}>
              <FontAwesomeIcon icon={faInfoCircle} />
            </h1>
            <h2>No hay contenido para mostrar!</h2>
            <p>Esta sección se encuentra vacía</p>
          </Container>
        </Jumbotron>
      </Col>
    </Row>
  );
};

export default EmptyDataSet;
