import React, { useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { Container, Col, Row, Card } from 'react-bootstrap';
import application from '../../../assets/img/solicitante.jpg';
import volunteer from '../../../assets/img/voluntario.jpg';
import { ProfileContext } from '../../../app/Context/ProfileContext';

const PrivateHome = (props) => {
  const { profile } = useContext(ProfileContext);
  const imgStyle = {
    height: 250,
  };
  const cardStyle = {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    top: 0,
    cursor: 'pointer',
    flexDirection: 'column',
  };

  const profiles = [
    {
      id: 1,
      title: 'VOLUNTARIO',
      text: 'Conéctate con PYMES para que logren solucionar sus problemas',
      img: volunteer,
      path: '/dashboard',
    },
    {
      id: 2,
      title: 'SOLICITAR AYUDA',
      text: 'Crea una solicitud para que un voluntario se conecte contigo',
      img: application,
      path: '/cases/new',
    },
  ];

  const setRole = (role) => {
    const { history } = props;

    profile.actions.setRole(role.id);
    history.push(role.path);
  };

  return (
    <Container className="mt-5 dashboard">
      <Row>
        <Col
          mg={{ span: 8, offset: 2 }}
          lg={{ span: 8, offset: 2 }}
          sm={{ span: 8, offset: 2 }}
        >
          <h1 className="text-center text-muted">Comencemos!</h1>
          <p className="text-center lead">
            Selecciona el tipo de usuario que quieras utilizar
          </p>
        </Col>
      </Row>
      <Row>
        {profiles.map((role) => {
          return (
            <Col md={6} lg={6} sm={12} key={`card-${role.id}`}>
              <Card
                className="animate__fadeIn animate__animated"
                onClick={() => {
                  setRole(role);
                }}
              >
                <Card.Img variant="top" src={role.img} style={imgStyle} />
                <Card.ImgOverlay style={cardStyle}>
                  <Card.Title className="text-center">
                    <h2>{role.title}</h2>
                  </Card.Title>
                  <Card.Text className="text-center" style={{ fontSize: 16 }}>
                    {role.text}
                  </Card.Text>
                </Card.ImgOverlay>
                <span className="gradient" />
              </Card>
            </Col>
          );
        })}
      </Row>
    </Container>
  );
};

export default withRouter(PrivateHome);
