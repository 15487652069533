import request from './../../utilities/request';

function register(payload) {
  return request({
    url: '/register',
    method: 'POST',
    data: payload,
  });
}

function categories() {
  return request({
    url: '/category',
    method: 'GET',
  });
}

function getCategory(id) {
  return request({
    url: `/category/${id}`,
    method: 'GET',
  });
}

function countries() {
  return request({
    url: '/country',
    method: 'GET',
  });
}

function subCategories(category) {
  return request({
    url: `/subcategory/${category}`,
    method: 'GET',
  });
}

const VoluntarioService = {
  register,
  categories,
  countries,
  subCategories,
  getCategory,
};

export default VoluntarioService;
