import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';

const DemoForm = (props) => {
  const [data, setData] = useState({});

  return (
    <Form className="text-left">
      <Form.Group controlId="email">
        <Form.Label>Email</Form.Label>
        <Form.Control type="email" placeholder="Ingresa tu email" />
      </Form.Group>
      <Form.Group controlId="fullName">
        <Form.Label>Nombre</Form.Label>
        <Form.Control type="text" placeholder="Ingresa tu nombre" />
      </Form.Group>
      <Form.Group controlId="roomName">
        <Form.Label>Código</Form.Label>
        <Form.Control
          type="text"
          placeholder="Ingresa el código que te enviamos"
        />
      </Form.Group>
      <Button variant="secondary" block type="submit" className="mt-5">
        Entrar a la reunión
      </Button>
    </Form>
  );
};

export default DemoForm;
