import request from './../../utilities/request';

function cases() {
  return request({
    url: '/case',
    method: 'GET',
  });
}

function singleCase(id) {
  return request({
    url: `/case/${id}`,
    method: 'GET',
  });
}

function schedule(payload) {
  return request({
    url: '/case/schedule',
    method: 'POST',
    data: payload,
  });
}

function mycalls() {
  return request({
    url: '/case/mycalls',
    method: 'GET',
  });
}

function myCases() {
  return request({
    url: '/case/mycases',
    method: 'GET',
  });
}

function rejectCall(payload) {
  return request({
    url: '/case/rejectcall',
    method: 'POST',
    data: payload,
  });
}

function confirmCall(payload) {
  return request({
    url: '/case/confirmcall',
    method: 'POST',
    data: payload,
  });
}

const deleteCase = (id) => {
  return request({
    url: `/case/${id}`,
    method: 'DELETE',
  });
};

const PortalService = {
  cases,
  singleCase,
  schedule,
  mycalls,
  rejectCall,
  confirmCall,
  myCases,
  deleteCase,
};

export default PortalService;
