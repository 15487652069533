import React from 'react';
import { withRouter } from 'react-router-dom';
import { Container, Row, Col, Form, Card, Button, Nav } from 'react-bootstrap';
import PortalService from './../PortalService';
import VoluntarioService from './../../Voluntario/VoluntarioService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle, faCompass } from '@fortawesome/free-solid-svg-icons';
import { removeDiacritics } from './../../../utilities/String';
import EmptyDataSet from './../../../common/EmptyDataSet';
import { ProfileContext } from './../../../app/Context/ProfileContext';

class Dashboard extends React.Component {
  state = {
    category: {
      selectedCategory: 'all',
      categories: [],
    },
    helpCase: {
      cases: [],
      filtered: [],
    },
    emptyDataSet: false,
  };
  componentDidMount = () => {
    this.fetchCategories();
    PortalService.cases().then(
      (response) => {
        this.setState({
          helpCase: {
            ...this.state.case,
            cases: response.data,
            filtered: response.data,
          },
        });
      },
      (error) => {}
    );

    setTimeout(() => {
      this.setState({
        ...this.state,
        emptyDataSet: true,
      });
    }, 200);
  };

  fetchCategories = () => {
    const { profile } = this.context;
    VoluntarioService.getCategory(profile.desiredCategory).then((response) => {
      const categories = [response.data];
      this.setState({
        category: {
          ...this.state.category,
          categories,
        },
      });
    });
  };

  truncate = (str) => {
    return str.length > 150 ? str.substring(0, 150) + '...' : str;
  };

  filter = (selectedKey) => {
    const { cases } = this.state.helpCase;

    let changeState = {
      category: {
        ...this.state.category,
        selectedCategory: selectedKey,
      },
    };
    if (selectedKey === 'all') {
      changeState['helpCase'] = {
        ...this.state.helpCase,
        filtered: cases,
      };
    } else {
      changeState['helpCase'] = {
        ...this.state.helpCase,
        filtered: cases.filter((row) => {
          return row.category.id === parseInt(selectedKey);
        }),
      };
    }

    this.setState(changeState);
  };

  searchBox = (e) => {
    const { cases } = this.state.helpCase;
    let value = e.target.value;
    this.setState({
      helpCase: {
        ...this.state.helpCase,
        filtered: cases.filter((row) => {
          return (
            removeDiacritics(row.summary)
              .toLowerCase()
              .indexOf(value.toLowerCase()) !== -1
          );
        }),
      },
    });
  };

  viewCase = (id) => {
    const { history } = this.props;
    history.push('/dashboard/view-case', {
      caseId: id,
    });
  };

  render() {
    const { category, helpCase, emptyDataSet } = this.state;
    return (
      <Container className="dashboard">
        <Row>
          <Col xs={12} sm={12} lg={12}>
            <div className="box">
              <Form.Control
                onChange={this.searchBox}
                name="search"
                placeholder="Filtra por tipos de casos"
              ></Form.Control>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Nav activeKey={category.selectedCategory} onSelect={this.filter}>
              <Nav.Item key="key-all">
                <Nav.Link eventKey="all">Todas</Nav.Link>
              </Nav.Item>
              {category.categories.map((link) => {
                return (
                  <Nav.Item key={`key-${link.id}`}>
                    <Nav.Link eventKey={link.id}>{link.name}</Nav.Link>
                  </Nav.Item>
                );
              })}
            </Nav>
          </Col>
        </Row>
        {helpCase.filtered.length === 0 && emptyDataSet ? (
          <EmptyDataSet />
        ) : (
          <Row>
            {helpCase.filtered.map((row) => {
              return (
                <Col
                  xs={12}
                  sm={12}
                  lg={4}
                  className="mb-4"
                  key={`case-${row.id}`}
                >
                  <Card className="animate__fadeIn animate__animated">
                    <Card.Body>
                      <Card.Title>{row.summary}</Card.Title>
                      <Card.Text
                        style={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {this.truncate(row.description)}
                      </Card.Text>
                      <Row style={{ marginBottom: 0 }}>
                        <Col>
                          <Card.Link>
                            <FontAwesomeIcon icon={faUserCircle} />
                            {row.owner.fullName}
                          </Card.Link>
                        </Col>
                        <Col className="text-right">
                          <Card.Link>
                            <FontAwesomeIcon icon={faCompass} />
                            {row.owner.country.name}
                          </Card.Link>
                        </Col>
                      </Row>
                    </Card.Body>
                    <Card.Footer>
                      <Button
                        variant="primary"
                        block
                        onClick={() => {
                          this.viewCase(row.id);
                        }}
                      >
                        Revisar
                      </Button>
                    </Card.Footer>
                  </Card>
                </Col>
              );
            })}
          </Row>
        )}
      </Container>
    );
  }
}

Dashboard.contextType = ProfileContext;
export default withRouter(Dashboard);
