import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

function Captcha(props) {
  const recaptchaRef = React.createRef();

  function recaptchaOnChange(value) {
    let state = {
      inputs: {
        ...props.inputs,
        'g-recaptcha-response': value,
      },
      errors: {
        ...props.errors,
        'g-recaptcha-response': false,
      },
    };
    props.recaptchaOnChange(state);
  }

  function recaptchaExpired() {
    let state = {
      inputs: {
        ...props.inputs,
        'g-recaptcha-response': '',
      },
      errors: {
        ...props.errors,
        'g-recaptcha-response': true,
      },
      isLoading: false,
    };
  }
  return (
    <ReCAPTCHA
      ref={props.captchaRef ? props.captchaRef : recaptchaRef}
      sitekey="6LfP7uUUAAAAAA6PcBtI4H5haB5CufKiHx7Gzosv"
      onChange={recaptchaOnChange}
      onExpired={recaptchaExpired}
    />
  );
}

export default Captcha;
